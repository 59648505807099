import eentr from "./media/eentr.svg"
import sun from "./media/sun.png"
import wind from "./media/wind.png"
import humidity from "./media/humidity.png"
import sunny from "./media/sunny.svg"
import cloudy from "./media/cloudy.svg"
import rainy from "./media/rainy.svg"
import "./App.css";
import { useEffect, useRef, useState } from "react";
import search from "./media/search.png"
function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [forecasts, setForecasts] = useState();
  const [currentCity, setCurrentCity] = useState([41.2647, 69.2163]);
  const [inputValue, setInputValue] = useState(" ");
  const [isSearch, setIsSearch] = useState(false);
  const conditions = {sunny:sunny,cloudy:cloudy,rainy:rainy}
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const getDayOfWeek = () => {
    return days[dayIndex];
  };

  let cities = [
    { city: "Toshkent", coord: [41.2647, 69.2163] },
    { city: "Namangan", coord: [40.9983, 71.6726] },
    { city: "Samarqand", coord: [39.6542, 66.9597] },
    { city: "Andijon", coord: [40.7821, 72.3442] },
    { city: "Nukus", coord: [42.4531, 59.6103] },
    { city: "Farg'ona", coord: [40.3842, 71.7843] },
    { city: "Qarshi", coord: [38.8606, 65.7891] },
    { city: "Buxoro", coord: [39.7747, 64.4286] },
    { city: "Qoqon", coord: [40.5286, 70.9425] },
    { city: "Margilon", coord: [40.4724, 71.7246] },
    { city: "Termiz", coord: [37.2242, 67.2783] },
    { city: "Angren", coord: [41.0167, 70.1436] },
    { city: "Jizzax", coord: [40.1234, 67.8284] },
    { city: "Chirchiq", coord: [41.4689, 69.5822] },
    { city: "Navoi", coord: [40.0844, 65.3792] },
    { city: "Urganch", coord: [41.5534, 60.6206] },
    { city: "Shahrisabz", coord: [39.0521, 66.8208] },
    { city: "Olmaliq", coord: [40.8447, 69.5983] },
    { city: "Bekobod", coord: [40.2208, 69.2697] },
    { city: "Quvasoy", coord: [40.2972, 71.9803] },
  ];
  let sd = "tashkent";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          
          `https://api.open-meteo.com/v1/forecast?latitude=${currentCity[0]}&longitude=${currentCity[1]}&current=wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m&daily=temperature_2m_max,sunrise,sunset,sunshine_duration,rain_sum,showers_sum,wind_speed_10m_max,wind_direction_10m_dominant&timezone=auto`
        );
        const json = await response.json();
        setData(json);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentCity]);
  const days = [
    "Yakshanba",
    "Dushanba",
    "Seshanba",
    "Chorshanba",
    "Payshanba",
    "Juma",
    "Shanba",
  ];
  const months = [
    "Yanvar",
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgus",
    "Sentyabr",
    "Oktyabr",
    "Noyabr",
    "Dekabr",
  ];
  const yourElement = useRef(null)
  const fcs = [
    {
      day: " ",
      date: " ",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: "",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: " ",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: "",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: "",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: "",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
    {
      day: "",
      date: "",
      month: "",
      hourly_temp: [],
      avg: "",
      sunrise_and_sunset: "",
      wind: "",
      humidity: "",
      cld: "",
      rain: "",
    },
  ];
  const today = new Date();
  const dayIndex = today.getDay();
  if (data) {
    let a = dayIndex;
    for (let i = 0; i < 7; i++) {
      fcs[i].day = days[a];
      fcs[i].date = data.daily.time[i].split("-").slice(1)[1];
      fcs[i].month =
        months[data.daily.time[i].split("-").slice(0)[1].split("0").join("")];
      a++;
      if (a > 6) {
        a = 0;
      }
      for (let j = 0; j < 24; j += 3) {
        fcs[i].hourly_temp.push(Math.ceil(data.hourly.temperature_2m[j]));
      }
      fcs[i].avg = Math.ceil(data.daily.temperature_2m_max[i]);
      fcs[i].sunrise_and_sunset =
        data.daily.sunrise[i].slice(11) +
        " - " +
        data.daily.sunset[i].slice(11);
      fcs[i].wind = ((data.daily.wind_speed_10m_max[i] / 10) * 2).toFixed(1);
      let b = 0;
      let c = i * 24 - i;
      for (let j = c; j < c + 24; j++) {
        b += data.hourly.relative_humidity_2m[j];
      }
      fcs[i].humidity = Math.ceil(b / 24) + "%";
      let aa = parseInt(data.daily.sunshine_duration[i]) < 42000 ? true : false;
      let ab = data.daily.rain_sum[i] > 0 ? true : false;
      if (ab) {
        fcs[i].cld = "rainy";
      } else if (aa) {
        fcs[i].cld = "cloudy";
      } else {
        fcs[i].cld = "sunny";
      }
    }
  }
  const handleBlur = () => {
    // Delay hiding the results to handle click events on results themselves
    
    if(searchTerm === ''){
      setSearchTerm("Toshkent")
      setIsSearch(false)
    }else{
      
      setTimeout(() => setIsSearch(false), 200);
    }
  };
  const [searchTerm, setSearchTerm] = useState('Toshkent');
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  
  const filteredResults = cities.filter((item) =>
    item.city.toLowerCase().includes(searchTerm === ''?null:searchTerm.toLowerCase())
  );


  const handleCityChange = (item, city) => {
    setCurrentCity(item)
    setSearchTerm(city)
  }
  const getHourly = () => {
    let divs = [];
    for(let i = 0; i < 8;i++){
      divs.push(
        <div className="hourlyunit">
          <div className="hour">{i * 3}:00</div>
          <div className="temp">{"+"+fcs[0].hourly_temp[i]+"°"}</div>
        </div>
      )
    }
    return divs;
  };
  const getWeekly = () => {
    let divs = [];
    for (let i = 1; i < 7; i++) {
      divs.push(
        <div className="day">
          <div className="d-top">
            <div className="t-a">{fcs[i].day}</div>
            <div className="t-b">
              {fcs[i].date.charAt(0) === "0"
                ? fcs[i].date.slice(1)
                : fcs[i].date}
            </div>
          </div>
          <div className="d-bottom">

          <div className="mid">{"+"+fcs[i].avg+"°"}</div>
          <img className='bottom'src={conditions[fcs[i].cld]} alt="" />
          </div>
        </div>
      );
    }
    return divs;
  };
  return (
    <div className="app">
      <div className="navbar">
        <img src={eentr}/>
      </div>
      <div className="cont">
        <div className="top">
          <div className="ta">
           <div className="td">Bugun</div>
            <div ref={yourElement} className="taa">
              <input
                type="text"
                onFocus={()=>setIsSearch(true)}
                onBlur={()=>handleBlur()}
                placeholder={searchTerm}
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <img className="searchIc" src={search} alt="" />
              <ul className="tsearch" style={{display: (isSearch && searchTerm !== '')?'block':'none'}}>
                {filteredResults.map((item) => (
                  <div key={item.id} onClick={() => handleCityChange(item.coord,item.city)} style={{marginTop:"20px",marginBottom:"20px",cursor:"pointer"}}>
                    {item.city} {item.definition}
                  </div>
                ))}
              </ul>
            </div>
            <div className="tab">
            {fcs[0].date + " " + fcs[0].month + " 2024"}
            </div>
          </div>
          <div className="tb">
            <div className="avg">{"+"+fcs[0].avg+"°"}</div>
            <div className="tcc">
            <div className="tc">
            <div className="tp">
            <img src={sun} className="sun" alt="" />
            {fcs[0].sunrise_and_sunset}</div>
            <div className="tp">
            <img src={wind} className="wind" style = {{marginLeft:'4px'}} alt="" />
              {fcs[0].wind + " m/s"}</div>
            <div className="tp">
            <img src={humidity} className="humidity" style = {{marginLeft:'6px',marginRight:'8px'}} alt=""/>
              {fcs[0].humidity}</div>
            </div>
            <div className="hourly">
            {getHourly()}
            </div>
            </div>
            <div className="condition"><img src={conditions[fcs[0].cld]} alt="" /></div>
          </div>
        </div>
        <div className="bottom">{getWeekly()}</div>
      </div>
      <div className="footer">
        <div className="">
          UNIWEBUZ
        </div>
      </div>
    </div>
  );
}

export default App;
